<template>
  <div class="main">
    <router-link to="/shops">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large">
        {{ $t('back') }} {{ $t('to') }} {{ $t('msg.platform.title') }}
      </a-button>
    </router-link>
    <div v-if="reference">
      <h2>{{ $t('msg.shop.flex.title') }}</h2>
      <p class="my-4">
        {{ $t('msg.shop.flex.subtitle') }}
      </p>
      <div class="card p-3">
        <div class="row card-body">
          <div class="col-xl-5 col-lg-12 mb-2">
            <h5>{{ $t('settings') }}</h5>
          </div>
          <div class="col-xl-7 col-lg-12">
            <!-- Week -->
            <a-checkbox v-model="configuration.week.enabled">
              {{ $t('msg.shop.flex.week') }}
            </a-checkbox>
            <a-form-model
              ref="week"
              :model="configuration.week"
              :rules="rules"
              v-if="configuration.week.enabled"
              class="mt-2">
              <!-- Cutoff -->
              <j-field
                prop="cutoff"
                :label="$t('msg.shop.flex.cutoff')">
                <a-select
                  v-model="configuration.week.cutoff"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.cutoff"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Capacity -->
              <j-field
                prop="capacity"
                :label="$t('msg.shop.flex.capacity')">
                <a-input-number
                  v-model="configuration.week.capacity"
                  :min="reference.configuration.availables.capacity_range.from"
                  :max="reference.configuration.availables.capacity_range.to"
                  style="width: 100%"
                  size="large" />
              </j-field>
              <!-- Range Min -->
              <j-field
                prop="from"
                :label="$t('msg.shop.flex.min')">
                <a-select
                  v-model="configuration.week.from"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Range Max -->
              <j-field
                prop="to"
                :label="$t('msg.shop.flex.max')">
                <a-select
                  v-model="configuration.week.to"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
            </a-form-model>
            <!-- Saturday -->
            <a-divider />
            <a-checkbox v-model="configuration.saturday.enabled">
              {{ $t('msg.shop.flex.saturday') }}
            </a-checkbox>
            <a-form-model
              ref="saturday"
              :model="configuration.saturday"
              :rules="rules"
              v-if="configuration.saturday.enabled"
              class="mt-2">
              <!-- Cutoff -->
              <j-field
                prop="cutoff"
                :label="$t('msg.shop.flex.cutoff')">
                <a-select
                  v-model="configuration.saturday.cutoff"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.cutoff"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Capacity -->
              <j-field
                prop="capacity"
                :label="$t('msg.shop.flex.capacity')">
                <a-input-number
                  v-model="configuration.saturday.capacity"
                  :min="reference.configuration.availables.capacity_range.from"
                  :max="reference.configuration.availables.capacity_range.to"
                  style="width: 100%"
                  size="large" />
              </j-field>
              <!-- Range Min -->
              <j-field
                prop="from"
                :label="$t('msg.shop.flex.min')">
                <a-select
                  v-model="configuration.saturday.from"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Range Max -->
              <j-field
                prop="to"
                :label="$t('msg.shop.flex.max')">
                <a-select
                  v-model="configuration.saturday.to"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
            </a-form-model>
            <!-- Sunday -->
            <a-divider />
            <a-checkbox v-model="configuration.sunday.enabled">
              {{ $t('msg.shop.flex.sunday') }}
            </a-checkbox>
            <a-form-model
              ref="sunday"
              :model="configuration.sunday"
              :rules="rules"
              v-if="configuration.sunday.enabled"
              class="mt-2">
              <!-- Cutoff -->
              <j-field
                prop="cutoff"
                :label="$t('msg.shop.flex.cutoff')">
                <a-select
                  v-model="configuration.sunday.cutoff"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.cutoff"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Capacity -->
              <j-field
                prop="capacity"
                :label="$t('msg.shop.flex.capacity')">
                <a-input-number
                  v-model="configuration.sunday.capacity"
                  :min="reference.configuration.availables.capacity_range.from"
                  :max="reference.configuration.availables.capacity_range.to"
                  style="width: 100%"
                  size="large" />
              </j-field>
              <!-- Range Min -->
              <j-field
                prop="from"
                :label="$t('msg.shop.flex.min')">
                <a-select
                  v-model="configuration.sunday.from"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
              <!-- Range Max -->
              <j-field
                prop="to"
                :label="$t('msg.shop.flex.max')">
                <a-select
                  v-model="configuration.sunday.to"
                  size="large"
                  style="width: 100%">
                  <a-select-option
                    v-for="value in reference.configuration.availables.ranges"
                    :key="value"
                    :value="value">
                    {{ value }} hs
                  </a-select-option>
                </a-select>
              </j-field>
            </a-form-model>
            <a-divider />
            <!-- Zones -->
            <!-- <a-form-model
              ref="general"
              :model="configuration.general"
              :rules="rules"
              class="mt-2">
              <j-field
                prop="zones"
                :label="$t('msg.shop.flex.zones')">
                <a-select
                  v-model="configuration.general.zones"
                  size="large"
                  style="width: 100%"
                  mode="multiple"
                  :max-tag-count="1">
                  <a-select-option
                    v-for="zone in reference.configuration.zones"
                    :key="zone.id"
                    :value="zone.id"
                    :disabled="zone.is_mandatory">
                    {{ zone.label }}
                  </a-select-option>
                </a-select>
              </j-field>
            </a-form-model> -->
            <!-- Submit -->
            <div class="font-size-18 mt-2">
              <a-button
                type="primary"
                size="large"
                @click="onSubmit">
                {{ $t('msg.shop.flex.submit') }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import v from '@/utils/validators'
import lms from '@/api/lms'
import JField from '@/views/shared/forms/field'

export default {
  components: {
    JField,
  },
  data: function () {
    return {
      reference: null,
      selected: 'week',
      configuration: {
        general: {
          zones: [],
        },
        week: {
          enabled: false,
          from: null,
          to: null,
          capacity: 0,
          cutoff: null,
        },
        saturday: {
          enabled: false,
          from: null,
          to: null,
          capacity: 0,
          cutoff: null,
        },
        sunday: {
          enabled: false,
          from: null,
          to: null,
          capacity: 0,
          cutoff: null,
        },
      },
      rules: {
        cutoff: [v.required],
        capacity: [v.required],
        from: [v.required],
        to: [v.required],
      },
    }
  },
  computed: {
    current() {
      return this.configuration[this.selected]
    },
  },
  mounted() {
    const id = this.$route.params.shop
    lms.platform.fetchConfiguration(id)
      .then(response => {
        this.reference = response.data
        const configuration = response.data.configuration
        if (configuration && configuration.delivery_ranges) {
          const ranges = configuration.delivery_ranges
          if (ranges.week && ranges.week.length) {
            const range = ranges.week[0]
            this.configuration.week.enabled = true
            this.configuration.week.cutoff = range.calculated_cutoff
            this.configuration.week.capacity = range.capacity
            this.configuration.week.from = range.from
            this.configuration.week.to = range.to
          }
          if (ranges.saturday && ranges.saturday.length) {
            const range = ranges.saturday[0]
            this.configuration.saturday.enabled = true
            this.configuration.saturday.cutoff = range.calculated_cutoff
            this.configuration.saturday.capacity = range.capacity
            this.configuration.saturday.from = range.from
            this.configuration.saturday.to = range.to
          }
          if (ranges.sunday && ranges.sunday.length) {
            const range = ranges.sunday[0]
            this.configuration.sunday.enabled = true
            this.configuration.sunday.cutoff = range.calculated_cutoff
            this.configuration.sunday.capacity = range.capacity
            this.configuration.sunday.from = range.from
            this.configuration.sunday.to = range.to
          }
        }
        if (configuration && configuration.zones) {
          this.configuration.general.zones = configuration.zones.filter(z => z.selected).map(z => z.id)
        }
      })
  },
  methods: {
    onSubmit() {
      const promises = []
      const id = this.$route.params.shop
      const payload = {
        delivery_window: 'same_day',
        delivery_ranges: {},
      }
      if (this.configuration.week.enabled) {
        payload.delivery_ranges.week = [this.configuration.week]
        promises.push(new Promise((resolve) => this.$refs.week.validate(resolve)))
      }
      if (this.configuration.saturday.enabled) {
        payload.delivery_ranges.saturday = [this.configuration.saturday]
        promises.push(new Promise((resolve) => this.$refs.saturday.validate(resolve)))
      }
      if (this.configuration.sunday.enabled) {
        payload.delivery_ranges.sunday = [this.configuration.sunday]
        promises.push(new Promise((resolve) => this.$refs.sunday.validate(resolve)))
      }
      Promise.all(promises).then(results => {
        if (results.every(Boolean)) {
          lms.platform.updateConfiguration(id, payload)
            .then(_ => this.$router.push('/shops'))
        }
      })
    },
  },
}
</script>
